import React, { useState, useEffect, useRef } from "react"
import {
  Add16,
} from '@carbon/icons-react'

import {
  Grid,
  Column,
  Row,
  MultiSelect,
} from 'carbon-components-react';

import NoUsersFound from "../../../assets/images/icons/search-users.svg"
import AddUserImage from "../../../assets/images/icons/add-user.svg";

import SearchBar from "../form_items/SearchBar"
import PaginationNav from "../form_items/PaginationNav"
import UserDetail from "./Detail"
import { getRequest } from "../../utils/api";

import { gaEvent } from "../../utils/ga";

const dealerGroupItems = gon.dealerGroupItems.map(({ id, name }) => ({ id: id, label: name }))
let regionItems = gon.regionItems.map(({ id, name }) => ({ id: id, label: name }))
let dealershipItems = gon.dealershipItems.map(({ id, name }) => ({ id: id, label: name }))

const defaultState = {
  searching: false,
  users: gon.users,
  userId: gon.userId,
  searchTerm: gon.searchTerm || "",
  currentPage: gon.currentPage,
  totalPages: gon.totalPages,
  totalCount: gon.totalCount,
  selectedDealerGroups: gon.filters.dealerGroup ? regionItems.filter(r => gon.filters.dealerGroup.includes(r.id.toString())) : [],
  selectedRegions: gon.filters.region ? regionItems.filter(r => gon.filters.region.includes(r.id.toString())) : [],
  selectedDealerships: gon.filters.dealership ? regionItems.filter(r => gon.filters.dealership.includes(r.id.toString())) : [],
}

const UsersList = () => {
  const [state, setState] = useState(defaultState)
  const isFirstRun = useRef(true);

  const {
    searching,
    users,
    userId,
    searchTerm,
    currentPage,
    totalPages,
    totalCount,
    selectedDealerGroups,
    selectedRegions,
    selectedDealerships,
  } = state

  const currentUser = users.find(user => user.id == userId)

  const handleSearchTermChange = (term) => {
    setState({ ...state,
      searching: term !== "",
      searchTerm: term,
      currentPage: 1,
    })
  }

  const handleDealerGroupsChange = ({selectedItems}) => {
    setState({ ...state, selectedDealerGroups: selectedItems, selectedRegions: [], selectedDealerships: [] })
    document.querySelectorAll(".region-selector .bx--list-box__selection--multi").forEach(e => e.click());
    document.querySelectorAll(".dealership-selector .bx--list-box__selection--multi").forEach(e => e.click());
  }

  const handleRegionsChange = ({selectedItems}) => {
    setState({ ...state, selectedRegions: selectedItems, selectedDealerships: [] })
    document.querySelectorAll(".dealership-selector .bx--list-box__selection--multi").forEach(e => e.click());
  }

  const handleDealershipsChange = ({selectedItems}) => {
    setState({ ...state, selectedDealerships: selectedItems })
  }

  const handlePageChange = (page) => {
    setState({ ...state, currentPage: page })
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    updateLocation();
  }, [userId])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    
    updateLocation();
    fetchSearchResults();
  }, [searchTerm, currentPage, selectedDealerGroups, selectedRegions, selectedDealerships])

  const updateLocation = () => {
    const params = {
      page: currentPage,
      term: searchTerm,
      dealer_group: selectedDealerGroups.map(dg => dg.id).join(","),
      region: selectedRegions.map(r => r.id).join(","),
      dealership: selectedDealerships.map(d => d.id).join(","),
      id: userId,
    }

    let searchParams = []
    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value && String(value).length > 0) {
        searchParams = [...searchParams, `${key}=${value}`]
      }
    })

    window.history.pushState("", "", `?${searchParams.join("&")}`);
  }

  const fetchSearchResults =  async () => {
    try {
      const { response, data } = await getRequest(window.location.href)
      const { users = [], userId, totalPages, totalCount, currentPage } = data
      regionItems = data.regionItems.map(({ id, name }) => ({ id: id, label: name }))
      dealershipItems = data.dealershipItems.map(({ id, name }) => ({ id: id, label: name }))

      if (response.ok) {
        setState({ ...state, users, userId, totalPages, totalCount, currentPage })
        window.scrollTo(0, 0)
      }
    } catch(e) {
      console.error("Search failed", e)
    }
  }

  const handleUserChange = (value) => {
    gaEvent("Users", "Select User click", `User: ${value}`)
    setState({ ...state, userId: value } )
  }

  const renderNoUsers = () => (
    <div className="fi--centered-content">
      <img src={AddUserImage} width="160" className="fi--mt--96"/>
      <p className="bx--type-expressive-heading-05 fi--mt--64">Add a user to get started</p>
      <p className="bx--type-body-short-02 fi--mt--24">Once you add a user, F&I Insight will send them an email invite to create a password.</p>
    </div>
  )

  const renderNotFound = () => (
    <React.Fragment>
      <div className="fi--centered-content">
        <img src={NoUsersFound} width="160" className="fi--mt--96"/>
        <p className="bx--type-expressive-heading-05 fi--mt--48">No users found</p>
        <p className="fi--mt--24">Try adjusting your search to find what you’re looking for.</p>
      </div>
    </React.Fragment>
  )

  const renderUsers = () => {
    return(
      <Grid fullWidth className="fi--px--0 fi--mt--24">
        <Row className="fi--px--16">
          <Column lg={5} className="fi--p--0">
            <div className="bx--tile fi--pt--16 fi--px--0 fi--pb--0">
              <h6 className="fi--mb--16 fi--ml--16">{totalCount} {totalCount != 1 ? "users" : "user"}</h6>
              <ul className="bx--side-nav__items fi--pt--0">
                {
                  users.map(user => (
                    <li  key={user.id} className={`bx--side-nav__item${userId == user.id ? " bx--side-nav__link--current" : ""} fi--border--top fi--height--auto fi--width--100`}>
                      <a className={`bx--side-nav__link fi--py--16${userId == user.id ? " bx--side-nav__link--current" : ""}`} onClick={() => handleUserChange(user.id)}>
                        <span className="bx--side-nav__link-text fi--font-weight--normal">{user.name}</span>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="bx--tile fi--border--top fi--p--0" style={{ minHeight: "0px" }}>
              {(totalCount > 0 ) &&
                <PaginationNav
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onChange={handlePageChange}
                />
              }
            </div>
          </Column>
          <Column lg={11} className="fi--pl--32 fi--pr--0">
            <UserDetail user={currentUser}/>
          </Column>
        </Row>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <div className="fi--mt--24 fi--d-flex">
        <a href="/users/new" className="bx--btn bx--btn--primary" data-gaevent="Add user click" data-gacategory="Users">
          <span>Add user</span>
          <Add16 className="bx--btn__icon"/>
        </a>

        <SearchBar
          initialValue={gon.searchTerm}
          onChange={handleSearchTermChange}
          delay={500}
        />
      </div>

      <Row className="fi--mt--24">
        { dealerGroupItems.length > 0 &&
          <Column sm={4} md={4} lg={4}>
            <MultiSelect
              id="dealer-group-filter"
              items={dealerGroupItems}
              label="Dealer groups"
              onChange={handleDealerGroupsChange}
              initialSelectedItems={selectedDealerGroups}
            />
          </Column>
        }
        { regionItems.length > 0 &&
        <Column sm={4} md={4} lg={4}>
          <div className="region-selector">
            <MultiSelect
              id="region-filter"
              items={regionItems}
              label="Regions"
              onChange={handleRegionsChange}
              initialSelectedItems={selectedRegions}
            />
          </div>
        </Column>
        }
        { dealershipItems.length > 0 &&
        <Column sm={4} md={4} lg={4}>
          <div className="dealership-selector">
            <MultiSelect
              id="dealership-filter"
              items={dealershipItems}
              label="Dealerships"
              onChange={handleDealershipsChange}
              initialSelectedItems={selectedDealerships}
            />
          </div>
        </Column>
        }
      </Row>

      { users.length == 0 ? (searching ? renderNotFound() : renderNoUsers()) : renderUsers() }

    </React.Fragment>
  )
}

export default UsersList
